<template>
    <div class="form-floating pb-3">
        <label class="floating-label" for="device">{{ $t('devices.type.buzzer') }}</label>
        <select v-model="modelValue.default_device_id" class="form-control cursor-pointer">
            <option :value="null" disabled selected>{{ $t("user.device.chooseDefaultBuzzer") }}</option>
            <option v-for="device in devices?.data"
                    :key="device.id"
                    :value="device.id"
                    data-icon="fa-chevron-down">
                {{ device.name }}
            </option>
        </select>
        <font-awesome-icon icon="fa-chevron-down" class="position-absolute top-50 end-0"/>
    </div>
</template>

<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useAuthStore} from "@/store/auth";

const props = defineProps({
    modelValue: {
        type: Object,
        default: {},
    },
    selectedOrganisation: {
        type: Number,
        default: null,
    }
})

const authStore = useAuthStore()

const {data: devices, refresh: refreshDevices} = await useAsyncData('devices', () =>
    $larafetch(useNuxtApp().$apiRoute('spa.devices.index'), {
        method: "GET",
        params: {
            filter: [
                {
                    "type": 'column',
                    "operation": 'eq',
                    "column": 'organisation_id',
                    "value": (props.selectedOrganisation) ? props.selectedOrganisation : authStore.userData.organisation_id,
                }
            ],
            buzzerOnly: true
        }
    }).catch(errors => {
        console.log(errors)
        return {data: []}
    })
)

onMounted(() => {
    refreshDevices()
})

watch(() => props.selectedOrganisation, () => {
    if (devices.value) {
        refreshDevices()
    }
})

watch(() => authStore.devicesUpdated, () => {
    if (authStore.devicesUpdated) {
        refreshDevices()
        authStore.devicesUpdated = false
    }
})
</script>

<style scoped>

</style>